import { alpha, Box, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { fileData } from "src/components/file-thumbnail";
import { fData } from "src/utils/formatNumber";

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
};

export default function RejectionFiles({ fileRejections }) {
  if (!fileRejections.length && fileRejections.length === 0) {
    return null;
  }

  const verifyError = (code, message) => {
    switch (code) {
      case "file-invalid-type":
        return `El tipo de archivo debe ser ${message.slice(18)}`;
      case "file-too-large":
        return "El archivo es demasiado grande";
      case "too-many-files":
        return "Demasiados archivos cargador";
      default:
        break;
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file);
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size ? fData(size) : ""}
            </Typography>

            {errors.map((error) => (
              <Box
                key={error.code}
                component="span"
                sx={{ typography: "caption" }}
              >
                - {verifyError(error.code, error.message)}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}
