import React, { useState, useEffect, useCallback } from "react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Step,
  StepLabel,
  Stepper,
  FormControl,
  Alert,
  Snackbar,
  TableHead,
  TextField,
  Paper,
  TableBody,
  FormLabel,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Stack,
  createFilterOptions,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { lowerCase, startCase } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { LoadingButton } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { es } from "date-fns/locale";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { useSelector } from "react-redux";
import { AlertaModal, AlertaToast } from "../AlertaSwall";
import { empleadosApi } from "src/services/empleadosApi";
import { clientesApi } from "src/services/clientesApi";
import { capitalizarPalabras } from "src/utils/sharedFunctions";
import { formatRut, RutFormat } from "@fdograph/rut-utilities";
import { fechaActual, formatearFecha } from "src/utils/formatTime";
import { tareasApi } from "src/services/tareasApi";
import { HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
  },
}));

const steps = ["Tarea", "Cliente", "Empleados", "Nota"];
export default function AgregarTareaInterna(props) {
  const classes = useStyles();
  const { openTareaInterna, setOpenTareaInterna } = props;
  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const { data: empleados, isLoading: cargandoEmpleados } =
    empleadosApi.useGetEmpleadosActivosQuery({ id: sucursal, estado: "ALL" });

  const { data: clientesActivos } = clientesApi.useGetClientesQuery({
    estado: "ACTIVO",
  });

  const [agregarTareaInterna] = tareasApi.useAgregarTareaInternaMutation();

  const [openNotificacion, setOpenNotificacion] = useState(false);
  const [colorNotificacion, setColorNotificacion] = useState("success");
  const [mensajeNotificacion, setMensajeNotificacion] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [mostrarCircular, setMostrarCircular] = useState(false);

  const [empleadosSeleccionados, setEmpleadosSeleccionados] = useState([]);
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
  const [opcionesEmpleados, setOpcionesEmpleados] = useState([]);

  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);

  const [skipped, setSkipped] = useState(new Set());

  const [nombreTarea, setNombreTarea] = useState("");
  const [descripcionTarea, setDescripcionTarea] = useState("");

  const [enviarNotificacion, setEnviarNotificacion] = useState("NO");
  const [notificarCreacion, setNotificarCreacion] = useState("NO");

  const [fechaLimiteTarea, setFechaLimiteTarea] = useState(null);

  const handleChangeEnviarNotificacion = (event) => {
    setEnviarNotificacion(event.target.value);
  };

  const handleChangeNotificarCreacion = (event) => {
    setNotificarCreacion(event.target.value);
  };

  const formatearEmpleadosSelect = useCallback(() => {
    setOpcionesEmpleados(empleados);
  }, [empleados]);

  useEffect(() => {
    if (!cargandoEmpleados && empleados) {
      formatearEmpleadosSelect();
    }
  }, [cargandoEmpleados, empleados, formatearEmpleadosSelect]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleCloseNotificacion = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificacion(false);
  };

  const handleNext = () => {
    if (activeStep === 0 && (nombreTarea === "" || nombreTarea === " ")) {
      setMensajeNotificacion("Nombre Tarea Requerido");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (activeStep === 1 && clienteSeleccionado === null) {
      setMensajeNotificacion("Se debe ingresar un cliente");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else if (activeStep === 2 && empleadosSeleccionados.length <= 0) {
      setMensajeNotificacion("Se debe ingresar al menos 1 empleado");
      setColorNotificacion("info");
      setOpenNotificacion(true);
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelar = () => {
    setOpenTareaInterna(false);
  };

  const agregarEmpleado = () => {
    if (!empleadoSeleccionado) {
      setMensajeNotificacion(`Debe seleccionar un empleado antes de agregar`);
      setColorNotificacion("error");
      setOpenNotificacion(true);
      return;
    }

    const empleadoYaAgregado = empleadosSeleccionados.find(
      ({ rut }) => rut === empleadoSeleccionado.rut
    );

    if (empleadoYaAgregado) {
      const nombreEmpleado = `${empleadoSeleccionado.nombre} ${empleadoSeleccionado.apellidos}`;
      setMensajeNotificacion(
        `El empleado: ${startCase(lowerCase(nombreEmpleado))} ya se añadió`
      );
      setColorNotificacion("error");
      setOpenNotificacion(true);
      return;
    }

    setEmpleadosSeleccionados((prevEmpleados) => [
      ...prevEmpleados,
      empleadoSeleccionado,
    ]);

    setEmpleadoSeleccionado(null);
  };

  const eliminarEmpleadoSeleccionado = (rutEmpleado) => {
    const empleadosFiltrados = empleadosSeleccionados.filter(
      ({ rut }) => rut !== rutEmpleado
    );

    setEmpleadosSeleccionados(empleadosFiltrados);
  };

  const guardar = () => {
    setMostrarCircular(true);

    let fechaLimite = "";
    if (fechaLimiteTarea) {
      fechaLimite = formatearFecha(fechaLimiteTarea, "YYYY/MM/DD");
    }

    AlertaModal({
      title: "crear la tarea",
      confirmButtonText: "Sí, crear",
      target: document.getElementById("dialog-agregar-tarea-interna"),
    })
      .fire()
      .then(async (result) => {
        if (result.isConfirmed) {
          const tareasEmpleados = empleadosSeleccionados.map((empleado) => ({
            refCliente: clienteSeleccionado.rut,
            refEmpleado: empleado.rut,
            nombre: nombreTarea.toUpperCase(),
            sucursal: sucursal,
            comentario: descripcionTarea.toUpperCase(),
            notificacion: enviarNotificacion,
            supervisor: user.rut,
            fechaLimite,
          }));

          const { data, error } = await agregarTareaInterna({
            tareas: tareasEmpleados,
            notificarCreacion
          });

          if (error) {
            console.error("Error al guardar tarea interna: ", error);
            const {
              status,
              data: { message },
            } = error;
            let messageToShow = "Error en el servidor, intente más tarde";
            if (status === 400) {
              messageToShow = message;
            }
            AlertaToast({
              action: messageToShow,
              fullText: true,
              type: "error",
              target: document.getElementById("dialog-agregar-tarea-interna"),
            }).fire();
            setMostrarCircular(false);
            return;
          }

          if (data) {
            AlertaToast({
              element: "Tareas",
              action: "agregadas",
            }).fire();

            setMostrarCircular(false);
            setOpenTareaInterna(false);
          }
        } else {
          setMostrarCircular(false);
        }
      });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${capitalizarPalabras(option.nombre)} ${option.rut}`,
  });

  const handleChangeNombreTarea = (event) => {
    setNombreTarea(event.target.value);
  };

  const handleChangeDescripcionTarea = (event) => {
    setDescripcionTarea(event.target.value);
  };

  return (
    <>
      <Dialog
        open={openTareaInterna}
        fullWidth={true}
        aria-labelledby="dialog-agregar-tarea-interna"
        maxWidth={"lg"}
        id="dialog-agregar-tarea-interna"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid item xs={11} md={11}>
              Nueva Tarea Interna
            </Grid>
            <Grid item xs={1} md={1} alignContent="end">
              <Button variant="contained" onClick={cancelar}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems="stretch"
                >
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ textAlign: "center", marginTop: "30px" }}
                  >
                    ¿Generar tarea?
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: "20px" }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          ¿Notificar diariamente (de lunes a viernes)?
                          <Tooltip
                            title="Se enviará un recordatorio diario por correo a los empleados asignados (lunes a viernes)"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "4px" }}
                            >
                              <HelpOutline fontSize="small" color="info" />
                            </IconButton>
                          </Tooltip>
                        </FormLabel>
                        <RadioGroup
                          value={enviarNotificacion}
                          onChange={handleChangeEnviarNotificacion}
                        >
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: "20px" }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          ¿Recibir resumen de la tarea creada?
                          <Tooltip
                            title="Recibirás un correo con el resumen de la tarea creada y los empleados asignados"
                            placement="right"
                            arrow
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "4px" }}
                            >
                              <HelpOutline fontSize="small" color="info" />
                            </IconButton>
                          </Tooltip>
                        </FormLabel>
                        <RadioGroup
                          value={notificarCreacion}
                          onChange={handleChangeNotificarCreacion}
                        >
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <div>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Volver
                    </Button>
                    <LoadingButton
                      color="primary"
                      onClick={guardar}
                      loading={mostrarCircular}
                    >
                      Generar
                    </LoadingButton>
                  </div>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 && (
                  <div style={{ marginTop: "20px" }}>
                    <Stack spacing={3} textAlign="center">
                      <Typography variant="h4" gutterBottom>
                        Nombre Tarea
                      </Typography>
                      <TextField
                        required
                        label="Nombre Tarea"
                        variant="outlined"
                        value={nombreTarea}
                        fullWidth
                        onChange={handleChangeNombreTarea}
                      />

                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={es}
                      >
                        <DatePicker
                          label="Fecha Limite Tarea (No Obligatorio)"
                          views={["year", "month", "day"]}
                          value={fechaLimiteTarea}
                          minDate={fechaActual()}
                          onChange={(newValue) => {
                            setFechaLimiteTarea(newValue);
                          }}
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) => {
                            // Filtra las props que no son necesarias para el TextField
                            const {
                              inputProps,
                              InputProps,
                              inputFormat,
                              ...otherParams
                            } = params;
                            return (
                              <TextField
                                {...otherParams}
                                inputProps={inputProps}
                                InputProps={InputProps}
                                helperText={
                                  inputProps?.placeholder || "dd/MM/yyyy"
                                }
                                fullWidth
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </div>
                )}

                {activeStep === 1 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Cliente
                    </Typography>

                    <Autocomplete
                      value={clienteSeleccionado}
                      options={clientesActivos}
                      autoHighlight
                      getOptionLabel={(option) =>
                        `${capitalizarPalabras(option.nombre)} (${formatRut(
                          option.rut,
                          RutFormat.DOTS_DASH
                        )})`
                      }
                      filterOptions={filterOptions}
                      noOptionsText="No se encontraron clientes"
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setClienteSeleccionado(newValue);
                        } else {
                          setClienteSeleccionado(null);
                        }
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{
                              fontSize: 15,
                              "& > span": { mr: "10px", fontSize: 18 },
                            }}
                            {...props}
                            key={option.rut}
                          >
                            {`${capitalizarPalabras(
                              option.nombre
                            )} (${formatRut(option.rut, RutFormat.DOTS_DASH)})`}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Seleccionar Cliente"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                )}

                {activeStep === 2 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Empleados
                    </Typography>

                    <FormControl
                      style={{ marginTop: "30px" }}
                      className={classes.formControl}
                    >
                      <Autocomplete
                        value={empleadoSeleccionado}
                        isOptionEqualToValue={(option, value) =>
                          option.rut === value.rut
                        }
                        getOptionLabel={(option) => {
                          const nombreEmpleado = `${option.nombre} ${option.apellidos}`;
                          return startCase(lowerCase(nombreEmpleado));
                        }}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setEmpleadoSeleccionado(newValue);
                          } else {
                            setEmpleadoSeleccionado(null);
                          }
                        }}
                        fullWidth
                        id="combo-box-empleados"
                        options={opcionesEmpleados}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            type="rut"
                            label="Empleados"
                            required
                            value={empleadoSeleccionado}
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl
                      style={{ marginTop: "40px" }}
                      className={classes.formControl}
                    >
                      <Button variant="contained" onClick={agregarEmpleado}>
                        Agregar Empleado
                      </Button>
                    </FormControl>

                    <TableContainer
                      component={Paper}
                      style={{ marginTop: "10px" }}
                    >
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre Empleado</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {empleadosSeleccionados.map((row) => (
                            <TableRow key={row.rut}>
                              <TableCell component="th" scope="row">
                                {row.nombre + " " + row.apellidos}
                              </TableCell>
                              <TableCell align="right">
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() =>
                                    eliminarEmpleadoSeleccionado(row.rut)
                                  }
                                >
                                  Eliminar
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {activeStep === 3 && (
                  <div>
                    <Typography
                      variant="h4"
                      gutterBottom
                      style={{ textAlign: "center", marginTop: "30px" }}
                    >
                      Nota o Descripción Tarea
                    </Typography>

                    <FormLabel component="legend">No Obligatorio</FormLabel>

                    <TextField
                      style={{ marginTop: "30px" }}
                      label="Descripción Tarea"
                      multiline
                      rows={4}
                      variant="outlined"
                      value={descripcionTarea}
                      fullWidth
                      onChange={handleChangeDescripcionTarea}
                    />
                  </div>
                )}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep === 0 ? (
                    <Button color="inherit" onClick={cancelar} sx={{ mr: 1 }}>
                      Cancelar
                    </Button>
                  ) : (
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Volver
                    </Button>
                  )}

                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openNotificacion}
        autoHideDuration={6000}
        onClose={handleCloseNotificacion}
      >
        <Alert
          onClose={handleCloseNotificacion}
          severity={colorNotificacion}
          sx={{ width: "100%" }}
        >
          {mensajeNotificacion}
        </Alert>
      </Snackbar>
      ;
    </>
  );
}
