import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { memo, useCallback, useEffect, useState } from "react";
import { DialogActions, Grid } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import Icon from "@iconify/react";
import saveFill from "@iconify/icons-eva/save-outline";
import dayjs from "dayjs";
import * as Yup from "yup";
import SucursalesField from "./Fields/SucursalesField";
import AutoCompleteField from "../sharedFields/AutocompleteField";
import FormControlField from "./Fields/FormControlField";
import CalendarField from "./Fields/CalendarField";
import TextFieldComponent from "../sharedFields/TextFieldComponent";
import { categoriasApi } from "src/services/categoriasApi";
import { gavsApi } from "src/services/gavsApi";
import { AlertaModal, AlertaToast } from "src/components/AlertaSwall";
import ShowFileComponent from "./Fields/ShowFile";
import { prorrateosApi } from "src/services/prorrateosApi";
import { validarFormatoFecha } from "src/utils/formatTime";
import {
  eliminarArchivoDropbox,
  subirArchivoADropbox,
} from "src/utils/dropboxUtils";
import CustomAutocomplete from "src/components/material/CustomAutocomplete";

const limpiarSelects = (setFieldValue, prorrateos) => {
  setFieldValue("selectedSucursales", prorrateos);
  setFieldValue("empleadosSeleccionados", []);
};

const FormGav = memo(({ setOpen, agregar, data, statesModal }) => {
  const [subcategoriaOptions, setSubcategoriaOptions] = useState([]);
  const [loadingDeleteFile, setIsloadingDeleteFile] = useState(false);
  const [seleccionarArchivo, setSeleccionarArchivo] = useState(
    !data.archivoPath
  );
  const [loading, setIsLoading] = useState(false);
  const [sinProrrateosConfigurados, setSinProrrateosConfigurados] =
    useState(false);
  const {
    data: empleadosProrrateo = [],
    isLoading: cargandoEmpleadosProrrateo,
  } = prorrateosApi.useObtenerEmpleadosProrrateoQuery();

  const {
    data: categoriasYSubcategorias = [],
    isLoading: isLoadingCategoriasYSubcategorias,
  } = categoriasApi.useObtenerCategoriasYsubcategoriasQuery();
  const { data: prorrateos = [], isLoading: isLoadingSucursales } =
    prorrateosApi.useObtenerSucursalesConProrrateoQuery();
  const [crearNuevoGav] = gavsApi.useCrearNuevoGavMutation();
  const [editarGav] = gavsApi.useEditarGavMutation();
  const [eliminarImagenGav] = gavsApi.useEliminarImagenGavMutation();

  useEffect(() => {
    if (!isLoadingSucursales && prorrateos.length === 0) {
      setSinProrrateosConfigurados(true);
      AlertaToast({
        action: "Debe configurar primero los prorrateos",
        type: "info",
        fullText: true,
      }).fire({ target: document.getElementById("dialog-gav") });
    }
  }, [isLoadingSucursales, prorrateos.length]);

  const handleAutocompleteChange = (event, newValue) => {
    setFieldValue("empleadosSeleccionados", newValue);
  };

  const ProrrateoSchema = Yup.object().shape({
    nombre: Yup.string().required("El nombre del GAV es requerido"),
    montoTotal: Yup.number()
      .min(0, "El monto debe ser un entero positivo")
      .required("El monto total es requerido")
      .test("isNumber", "Ingrese un monto válido", (value) => {
        return /^[0-9]*$/.test(value);
      })
      .test("isNumber", "Ingrese un monto válido", (value) => !isNaN(value))
      .typeError("El valor del monto es inválido. Favor, verifique"),
    fechaGasto: Yup.date()
      .required("La fecha del gasto es requerida")
      .test(
        "formatoFecha",
        "Formato de fecha inválido. Use DD/MM/YYYY",
        (value) => validarFormatoFecha(value, "DD/MM/YYYY")
      )
      .transform((originalValue, originalObject) => {
        return validarFormatoFecha(originalValue, "DD/MM/YYYY")
          ? dayjs(originalValue).toDate()
          : originalValue;
      })
      .typeError("Formato de fecha inválido. Use DD/MM/YYYY"),
    fechaFacturacion: Yup.date()
      .required("La fecha de facturación es requerida")
      .test("formatoFecha", "Formato de fecha inválido. Use MM/YYYY", (value) =>
        validarFormatoFecha(value, "MM/YYYY")
      )
      .transform((originalValue, originalObject) => {
        return validarFormatoFecha(originalValue, "MM/YYYY")
          ? dayjs(originalValue).toDate()
          : originalValue;
      })
      .typeError("Formato de fecha inválido. Use MM/YYYY"),
    division: Yup.object()
      .shape({
        id: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required("La división es requerida"),
    // archivo: Yup.mixed().test(
    //   "required",
    //   "El archivo es requerido",
    //   (value) => {
    //     return value instanceof File || (!!value && typeof value === "string");
    //   }
    // ),
    archivo: Yup.mixed(),
    empleadosSeleccionados: Yup.array().when("division.id", {
      is: "empleadoEspecifico",
      then: Yup.array()
        .min(1, "Debe seleccionar al menos un empleado")
        .of(
          Yup.object().shape({
            rut: Yup.string().required("El rut es requerido"),
            nombre: Yup.string().required("El nombre es requerido"),
            sucursal: Yup.string().required("La sucursal es requerido"),
          })
        ),
      otherwise: Yup.array().nullable(true),
    }),
    selectedSucursales: Yup.array().when("division.id", {
      is: (id) => id === "sucursal" || id === "empleado",
      then: Yup.array()
        .min(1, function (value) {
          // Condición personalizada según el valor de division.id
          return formik.values.division.id === "sucursal"
            ? "Debe seleccionar al menos una sucursal"
            : "Debe seleccionar al menos una sucursal de los empleados";
        })
        .of(
          Yup.object().shape({
            id: Yup.number().required("El id es requerido"),
            nombre: Yup.string().required(
              "El nombre de la sucursal es requerido"
            ),
          })
        ),
    }),
    categoriaGasto: Yup.number().required("La categoría es requerida"),
    tipoGasto: Yup.number().required("El tipo de gasto es requerido"),
    comentario: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      nombre: agregar ? "" : data.nombre,
      montoTotal: agregar ? "" : data.montoTotal,
      fechaGasto: agregar ? new Date() : data.fechaGasto,
      fechaFacturacion: agregar ? new Date() : data.fechaFacturacion,
      // : dayjs(data.fechaFacturacion, "DD/MM/YYYY").toDate(),
      division: agregar
        ? divisiones.find((option) => option.id === divisiones[0].id)
        : divisiones.find((option) => option.id === data.division),
      empleadosSeleccionados: [],
      selectedSucursales: [],
      archivo: agregar ? "" : data.archivoPath,
      categoriaGasto: agregar ? "" : data.refCategoria,
      tipoGasto: agregar ? "" : "",
      // : obtenerSubcategoria(data.refCategoria, data.tipoGasto),
      comentario: agregar ? "" : data.comentario,
    },
    validationSchema: ProrrateoSchema,
    onSubmit: async () => {
      await guardarDatos();
    },
  });

  const {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik;

  const handleClose = () => {
    setOpen({
      ...statesModal,
      abrirModal: false,
      seAgregaCategoria: false,
    });
  };

  useEffect(() => {
    if (
      !agregar &&
      !cargandoEmpleadosProrrateo &&
      data?.snapshots?.length > 0 &&
      empleadosProrrateo.length > 0
    ) {
      const empleadosCargados = data.snapshots
        .map((empleadoSnap) => {
          return empleadosProrrateo.find(
            (empleadoOption) => empleadoOption.rut === empleadoSnap.refEmpleado
          );
        })
        .filter(Boolean); // Filtra los valores nulos o indefinidos en caso de que no coincidan.

      setFieldValue("empleadosSeleccionados", empleadosCargados);
    }
  }, [
    agregar,
    cargandoEmpleadosProrrateo,
    data?.snapshots,
    empleadosProrrateo,
    setFieldValue,
  ]);

  useEffect(() => {
    if (!isLoadingSucursales && prorrateos?.length > 0) {
      setFieldValue("selectedSucursales", prorrateos);

      if (!agregar && data?.snapshots) {
        const sucursalesCargadas = data.snapshots
          .map((sucursal) => {
            return prorrateos.find(
              (prorrateo) => prorrateo.nombre === sucursal
            );
          })
          .filter(Boolean); // Filtra los valores nulos o indefinidos en caso de que no coincidan.

        setFieldValue("selectedSucursales", sucursalesCargadas);
      }
    }
  }, [
    agregar,
    data?.snapshots,
    isLoadingSucursales,
    prorrateos,
    setFieldValue,
  ]);

  // Función para manejar cambio en la categoría de gasto
  const handleCategoriaGastoChange = useCallback(
    (event) => {
      const categoria = event.target.value;
      setFieldValue("categoriaGasto", categoria);

      const subcategorias = categoriasYSubcategorias.find(
        (element) => element.id === categoria
      ).subcategorias;

      // Actualizar opciones de subcategorías solo si hay cambios
      if (subcategorias) {
        setSubcategoriaOptions(subcategorias);
        // Resetear el tipo de gasto solo si es necesario
        if (values.tipoGasto !== "") {
          setFieldValue("tipoGasto", "");
        }
      }
    },
    [categoriasYSubcategorias, setFieldValue, values.tipoGasto]
  );

  const colocarDatosSubcategoriaBD = useCallback(() => {
    if (!agregar) {
      if (categoriasYSubcategorias.length > 0) {
        const subcategorias = categoriasYSubcategorias.find(
          (categoria) => categoria.id === values.categoriaGasto
        ).subcategorias;

        if (subcategorias) {
          setSubcategoriaOptions(subcategorias);
          setFieldValue("tipoGasto", data.refSubcategoria);
        }
      }
    }
  }, [
    agregar,
    categoriasYSubcategorias,
    data.refSubcategoria,
    setFieldValue,
    values.categoriaGasto,
  ]);

  useEffect(() => {
    colocarDatosSubcategoriaBD();
  }, [colocarDatosSubcategoriaBD]);

  const handleTipoCategoriaChange = useCallback(
    (event) => {
      const subcategoria = event.target.value;
      setFieldValue("tipoGasto", subcategoria);
    },
    [setFieldValue]
  );

  const handleSucursalSelection = useCallback(
    (event, newValue) => {
      limpiarSelects(setFieldValue, prorrateos);
      setFieldValue("division", newValue);
    },
    [prorrateos, setFieldValue]
  );

  const handleChangeFechaGasto = useCallback(
    (newValue) => setFieldValue("fechaGasto", newValue),
    [setFieldValue]
  );

  const handleChangeFechaFacturacion = useCallback(
    (newValue) => setFieldValue("fechaFacturacion", newValue),
    [setFieldValue]
  );

  const handleOptionLabelDivision = useCallback((option) => option.label, []);

  const handleIsOptionEqualToValueDivision = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const handleFileChange = useCallback(
    (event) => {
      const selectedFile = event.target.files[0];
      setFieldValue("archivo", selectedFile);
    },
    [setFieldValue]
  );

  const handleDeleteFile = useCallback(
    async (id) => {
      setIsloadingDeleteFile(true);
      AlertaModal({
        title: "eliminar la imagen",
        confirmButtonText: "Sí, eliminar",
      })
        .fire({ target: document.getElementById("dialog-gav") })
        .then(async (result) => {
          if (!result.isConfirmed) {
            setIsloadingDeleteFile(false);
            return;
          }
          //Se extrae de la url el nombre del archivo a eliminar.
          const coincidencia = values.archivo.match(expresionRegular);
          const nombre = coincidencia ? coincidencia[0] : null;

          try {
            const body = {
              id: id,
            };
            await eliminarArchivoDropbox("Gavs", nombre);
            await eliminarImagenGav(body);
            setFieldValue("archivo", "");
            setIsloadingDeleteFile(false);
            setSeleccionarArchivo(true);
          } catch (error) {
            AlertaToast({
              action: "Error al eliminar la imagen",
              type: "error",
              fullText: true,
            }).fire();
            setIsloadingDeleteFile(false);
            console.error("Error en HandleDeleteFile", error);
          }
        });
    },
    [eliminarImagenGav, setFieldValue, values.archivo]
  );

  // const subirArchivoADropbox = async (archivo, body) => {
  //   const timestamp = Date.now();
  //   // Obtener el hash del contenido del archivo
  //   const contenidoBuffer = await archivo.arrayBuffer();
  //   const contenidoHash = await hashBuffer(contenidoBuffer);

  //   const extension = archivo.name.split(".").pop();
  //   const nombreArchivoUnico = `archivo_gav_${timestamp}_${contenidoHash}.${extension}`;

  //   try {
  //     await dbx.filesUpload({
  //       path: `/Gavs/${nombreArchivoUnico}`,
  //       contents: archivo,
  //       mode: "overwrite",
  //     });

  //     try {
  //       const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
  //         path: `/Gavs/${nombreArchivoUnico}`,
  //       });
  //       let url = shareResponse.result.url;

  //       body = { ...body, archivo: url };
  //       return body;
  //     } catch (ex) {
  //       const { url } = ex.error.error.shared_link_already_exists.metadata;

  //       body = { ...body, archivo: url };
  //       return body;
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     throw error;
  //   }
  // };

  const guardarDatos = async () => {
    setIsLoading(true);

    let body = {
      nombre: values.nombre,
      montoTotal: values.montoTotal,
      fechaGasto: values.fechaGasto,
      fechaFacturacion: values.fechaFacturacion,
      division: values.division.id,
      archivo: values.archivo,
      sucursales: values.selectedSucursales,
      refCategoria: values.categoriaGasto,
      refSubcategoria: values.tipoGasto,
      comentario: values.comentario,
      empleadosSeleccionados: values.empleadosSeleccionados,
    };

    if (values.archivo instanceof File) {
      try {
        body = await subirArchivoADropbox(
          values.archivo,
          "Gavs",
          body,
          setIsLoading
        );
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        AlertaToast({
          action: "Error al subir archivo a dropbox",
          fullText: true,
          type: "error",
          target: document.getElementById("dialog-gav"),
        }).fire();
        return;
      }
    }

    if (agregar) {
      crearNuevoGav(body)
        .then((response) => {
          AlertaToast({
            element: "Nuevo GAV",
            action: "agregado",
          }).fire();
          handleClose();
        })
        .catch((error) => {
          AlertaToast({
            action: "Error al agregar el nuevo GAV",
            fullText: true,
            type: "error",
          }).fire();
          console.error(
            "Error al crear datos en guardarDatos(formGav); ",
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      body = {
        id: data.id,
        ...body,
      };

      editarGav(body)
        .then((response) => {
          if (response.data.mensaje === "Éxito") {
            AlertaToast({
              element: "Datos gav",
              action: "editados",
            }).fire();
          } else {
            AlertaToast({
              action: "Error al editar el GAV",
              fullText: true,
              type: "error",
            }).fire();
          }
          handleClose();
        })
        .catch((error) => {
          AlertaToast({
            action: "Error al editar el GAV",
            fullText: true,
            type: "error",
          }).fire();
          console.error(
            "Error al editar datos en guardarDatos(formGav)",
            error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={2} marginTop={1} marginBottom={1}>
          <Grid item xs={6}>
            <TextFieldComponent
              campo="nombre"
              label="Nombre"
              value={values.nombre}
              touched={touched.nombre}
              errors={errors.nombre}
              getFieldProps={getFieldProps("nombre")}
              inputAdornment="description"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldComponent
              campo="montoTotal"
              label="Monto total"
              value={values.montoTotal}
              touched={touched.montoTotal}
              errors={errors.montoTotal}
              getFieldProps={getFieldProps("montoTotal")}
              inputAdornment="dollar"
            />
          </Grid>
          <CalendarField
            id="fecha-gasto"
            label="Fecha gasto"
            value={values.fechaGasto}
            onChange={handleChangeFechaGasto}
            inputFormat="dd/MM/yyyy"
            touched={touched.fechaGasto}
            errors={errors.fechaGasto}
          />
          <CalendarField
            id="fecha-facturacion"
            label="Fecha facturación"
            value={values.fechaFacturacion}
            onChange={handleChangeFechaFacturacion}
            inputFormat="MM/yyyy"
            views={["month", "year"]}
            touched={touched.fechaFacturacion}
            errors={errors.fechaFacturacion}
          />
          <AutoCompleteField
            disableClearable={true}
            id="autocomplete-division"
            label="División"
            value={values.division}
            xs={6}
            lg={6}
            md={6}
            handleSucursalSelection={handleSucursalSelection}
            options={divisiones}
            touched={touched.division}
            error={errors.division}
            getOptionLabel={handleOptionLabelDivision}
            isOptionEqualToValue={handleIsOptionEqualToValueDivision}
            typeRenderInput="normal"
          />
          {!agregar && values.archivo && !seleccionarArchivo ? (
            <ShowFileComponent
              value={values.archivo}
              label="Ver archivo"
              tamanio={6}
              onDeleteFile={handleDeleteFile.bind(null, data.id)}
              loading={loadingDeleteFile}
            />
          ) : (
            <Grid item xs={6}>
              <TextFieldComponent
                campo="archivo"
                label="Archivo"
                value={values.archivo ? values.archivo : ""}
                inputAdornment="file"
                fileInputProps={{
                  onChange: handleFileChange,
                  name: "[licenseFile]",
                }}
              />
            </Grid>
          )}

          {values.division.id === "empleado" ||
          values.division.id === "sucursal" ? (
            <SucursalesField
              sucursales={prorrateos}
              selectedSucursales={values.selectedSucursales}
              setSelectedSucursales={setFieldValue}
              loading={isLoadingSucursales}
              colorChip={
                values.division.id === "sucursal" ? "secondary" : "primary"
              }
              error={
                touched.selectedSucursales && Boolean(errors.selectedSucursales)
              }
              helperText={
                touched.selectedSucursales && errors.selectedSucursales
              }
            />
          ) : (
            <Grid item xs={12}>
              <CustomAutocomplete
                options={empleadosProrrateo}
                maxTags={4}
                label="Empleados"
                placeholder="Seleccione empleado"
                value={values.empleadosSeleccionados}
                onChange={handleAutocompleteChange}
                error={errors.empleadosSeleccionados}
                touched={
                  Array.isArray(touched.empleadosSeleccionados)
                    ? touched.empleadosSeleccionados.length > 0
                    : touched.empleadosSeleccionados
                }
                loading={cargandoEmpleadosProrrateo}
              />
            </Grid>
          )}

          <FormControlField
            tamanio={6}
            touched={touched.categoriaGasto}
            errors={errors.categoriaGasto}
            inputLabelText="Categoría gasto"
            id="categoria-gasto"
            label="Categoría gasto"
            getFieldProps={getFieldProps("categoriaGasto")}
            value={values.categoriaGasto}
            handleChange={handleCategoriaGastoChange}
            values={categoriasYSubcategorias}
            loading={isLoadingCategoriasYSubcategorias}
          />
          <FormControlField
            tamanio={6}
            touched={touched.tipoGasto}
            errors={errors.tipoGasto}
            inputLabelText="Tipo gasto"
            id="tipo-gasto"
            label="Tipo gasto"
            getFieldProps={getFieldProps("tipoGasto")}
            value={values.tipoGasto}
            handleChange={handleTipoCategoriaChange}
            loading={isLoadingCategoriasYSubcategorias}
            values={subcategoriaOptions}
          />
          <Grid item xs={12}>
            <TextFieldComponent
              campo="comentario"
              label="Comentario"
              value={values.comentario}
              touched={touched.comentario}
              errors={errors.comentario}
              getFieldProps={getFieldProps("comentario")}
              inputAdornment="comment"
            />
          </Grid>
        </Grid>
        <DialogActions>
          <LoadingButton onClick={handleClose} color="error">
            Cancelar
          </LoadingButton>
          <LoadingButton
            type="submit"
            disabled={isSubmitting || sinProrrateosConfigurados}
            startIcon={<Icon icon={saveFill} />}
            color="primary"
            loading={loading}
          >
            Guardar Prorrateo
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}, []);

const divisiones = [
  { id: "sucursal", label: "Sucursal" },
  { id: "empleado", label: "Empleados" },
  { id: "empleadoEspecifico", label: "Empleados específicos" },
];

const expresionRegular = /\/(archivo_gav_|archivo_)[^?]+/i;

export default FormGav;
