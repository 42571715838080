import React, { useState, useEffect, useRef } from "react";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  Alert,
  Button,
  Card,
  IconButton,
  ListItemSecondaryAction,
  ListItem,
  List,
  ListItemText,
  Container,
  Grid,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import DownloadIcon from "@material-ui/icons/Download";
import axios from "axios";

import { ACCESS_TOKEN, dbx } from "../../utils/urlBack_End";
import { API_SERVER } from "../../utils/urlBack_End";
import { TemplateHandler } from "easy-template-x";
import { AlertaMensajeEliminacion } from "../AlertaMensajeEliminacion";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  hidden: {
    display: "none",
  },
}));
// ----------------------------------------------------------------------

export default function ArchivosAdjuntos(props) {
  const { setShowError, setError, tarea } = props;
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState(null);

  const [mostrarAlertaEliminacion, setMostrarAlertaEliminacion] =
    useState(false);
  const [elementoEliminar, setElementoEliminar] = useState(null);

  const { empleado: user, sucursal: sucursalObtenida } = useSelector(
    (state) => state.auth.user
  );
  const sucursal = sucursalObtenida.refSucursal;

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFileInput = (e) => {
    const fileAux = e.target.files[0];
    if (fileAux === undefined) {
      return;
    }

    setFile(fileAux);
  };

  const handleDeleteFile = () => {
    setFile(null);
  };

  const removeFile = async (nombre, idArchivo) => {
    setLoading(true);
    try {
      dbx
        .filesDeleteV2({ path: `/Tareas/${tarea.id}/${nombre}` })
        .then(() => {
          const query = [
            `${API_SERVER}/tareas/archivos/${idArchivo}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            },
          ];

          axios.delete(query[0], query[1]).then((response) => {
            if (response.data === -2) window.location.reload();
            axios
              .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then((data) => {
                if (data.data === -2) window.location.reload();
                setArchivos(data.data);
              })
              .catch((error) => {});
          });
        })
        .catch(() => {
          setError("No se pudo eliminar el archivo adjunto.");
          setShowError(true);
        });
    } catch (ex) {
      setError("No se pudo eliminar el archivo adjunto.");
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const updateTaskInDB = async (task) => {
    const update = {
      nombreArchivo: task.nombreArchivo,
      tipoArchivo: task.tipoArchivo,
      link: task.link,
      idTarea: task.idTarea,
    };

    const query = [
      `${API_SERVER}/tareas/archivos`,
      {
        ...update,
      },
    ];
    try {
      const response = await axios.post(query[0], query[1], {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data === -2) window.location.reload();
      axios
        .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((data) => {
          if (data.data === -2) window.location.reload();
          setArchivos(data.data);
        })
        .catch((error) => {});
    } catch (ex) {
      setError("No se pudo actualizar la tarea en la base de datos.");
      setShowError(true);
    }
  };

  const actualizarTareaDB = async (task) => {
    const update = {
      nombreArchivo: task.nombreArchivo,
      tipoArchivo: task.tipoArchivo,
      link: task.link,
    };
    const query = [
      `${API_SERVER}/tareas/archivos/${task.idTarea}`,
      {
        ...update,
      },
    ];
    try {
      const response = await axios.post(query[0], query[1], {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data === -2) window.location.reload();
      axios
        .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((data) => {
          if (data.data === -2) window.location.reload();
          setArchivos(data.data);
        })
        .catch((error) => {});
    } catch (ex) {
      setError("No se pudo actualizar la tarea en la base de datos.");
      setShowError(true);
    }
  };

  async function uploadFile() {
    setLoading(true);
    if (!file) return setLoading(false);
    const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;

    if (file.size >= UPLOAD_FILE_SIZE_LIMIT) {
      setError("El tamaño máximo de un archivo es de 150 MB.");
      setShowError(true);
      return;
    }

    try {
      await dbx.filesUpload({
        path: "/Tareas/" + tarea.id + "/" + file.name,
        contents: file,
        mode: "overwrite",
      });
      if (!archivos.some((archivo) => archivo.nombreArchivo === file.name)) {
        // si es que se sube bien, no devuelve la URL
        try {
          const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
            path: "/Tareas/" + tarea.id + "/" + file.name,
          });
          let url = shareResponse.result.url;

          let tareaAux = {
            nombreArchivo: file.name,
            tipoArchivo: file.type,
            link: url,
            idTarea: tarea.id,
          };
          updateTaskInDB(tareaAux);
        } catch (ex) {
          setError("El archivo ya existe en el sistema.");
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const shareResponse = await dbx.sharingCreateSharedLinkWithSettings({
            path: "/Tareas/" + tarea.id + "/" + file.name,
          });
          let url = shareResponse.result.url;

          let tareaAux = {
            nombreArchivo: file.name,
            tipoArchivo: file.type,
            link: url,
            idTarea: tarea.id,
          };
          actualizarTareaDB(tareaAux);
        } catch (ex) {
          setError("El archivo ya existe en el sistema.");
        } finally {
          setLoading(false);
        }
      }
    } catch (ex) {
      setError("Ha ocurrido un error que impidió guardar los cambios.");
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  function saveFile(filename, blob) {
    const blobUrl = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
      window.URL.revokeObjectURL(blobUrl);
      link = null;
    }, 0);
  }

  function capitalizarPalabras(val) {
    return val
      .toLowerCase()
      .trim()
      .split(". ")
      .map((v) => v[0].toUpperCase() + v.substr(1))
      .join(". ");
  }

  const generateDocument = async () => {
    let dropboxEndpoint1 = "https://content.dropboxapi.com/2/files/download";
    let dropBoxApiArg = { path: "/Archivos/PropuestaMODELO.docx" };
    let postOptions1 = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ACCESS_TOKEN}`,
        "Dropbox-API-Arg": JSON.stringify(dropBoxApiArg),
      },
    };
    const response = await fetch(dropboxEndpoint1, postOptions1);
    const templateFile = await response.blob();
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let yyyy = today.getFullYear();

    const cliente = await axios.get(
      `${API_SERVER}/clientes/${tarea.refCliente}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (cliente.status === 200) {
      if (cliente.data) {
        let planesAux = [];

        let decripcionTareaAUX = "";

        if (tarea.comentario !== "") {
          decripcionTareaAUX = capitalizarPalabras(tarea.comentario);
        }

        planesAux.push({
          descripcion: decripcionTareaAUX,
          nombreCliente: cliente.data.nombre,
          nombrePlan: tarea.nombre,
          tipoMoneda: tarea.tipoMonedaTarea,
          valor: tarea.valorTarea,
        });

        let meses = [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];

        today = dd + " de " + meses[today.getMonth()] + " de " + yyyy;
        const data = {
          ciudad: capitalizarPalabras(sucursalObtenida.comuna),
          fechaActual: today,
          nombreCliente: cliente.data.nombre,
          nombreEmpleado: user.nombre + " " + user.apellidos,
          Planes: planesAux,
        };
        const handler = new TemplateHandler();
        const doc = await handler.process(templateFile, data);
        saveFile("Propuesta " + cliente.data.nombre + ".docx", doc);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${API_SERVER}/tareas/archivos/${tarea.id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((data) => {
        if (data.data === -2) window.location.reload();
        if (isMounted.current) {
          setArchivos(data.data);
        }
      })
      .catch((error) => {});
  }, [tarea.id]);

  return (
    <Container>
      <RootStyle>
        {tarea.estado &&
        (tarea.estado === "TERMINADA" ||
          tarea.estado === "FACTURADO" ||
          tarea.estado === "PENDIENTE DE FACTURACION") ? (
          <></>
        ) : (
          <Card style={{ padding: "1rem" }}>
            {tarea.tipoTarea === "ESPECIAL" && (
              <Button
                disabled={
                  tarea.estado &&
                  (tarea.estado === "TERMINADA" ||
                    tarea.estado === "FACTURADO" ||
                    tarea.estado === "PENDIENTE DE FACTURACION")
                }
                onClick={generateDocument}
                variant="contained"
                fullWidth
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <DownloadIcon /> Descargar Prototipo Propuesta
              </Button>
            )}

            {tarea.estado &&
            (tarea.estado === "TERMINADA" ||
              tarea.estado === "FACTURADO" ||
              tarea.estado === "PENDIENTE DE FACTURACION") ? (
              <></>
            ) : (
              <>
                <Alert severity="info">
                  Haga click en el botón 'Seleccionar Archivo' para subir un
                  documento asociado a esta tarea.
                </Alert>

                <Grid container style={{ marginTop: "10px" }}>
                  <Grid item xs={4} md={4}>
                    <input
                      className={classes.hidden}
                      id="contained-button-file"
                      onChange={handleFileInput}
                      type="file"
                    />

                    <label htmlFor={"contained-button-file"}>
                      <Button
                        fullWidth
                        component="span"
                        m={1}
                        variant="contained"
                      >
                        Seleccionar Archivo
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {file && (
                      <span>
                        <span>
                          <> {file.name}</>
                        </span>

                        <span>
                          <IconButton
                            onClick={handleDeleteFile}
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </span>
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      onClick={uploadFile}
                      variant="contained"
                    >
                      Subir
                    </LoadingButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        )}

        <Card>
          <List className={classes.root}>
            {archivos &&
              archivos.map((value, idx) => {
                return (
                  <ListItem key={"archivos_" + idx}>
                    <ListItemText primary={value.nombreArchivo} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={() => {
                          window.open(value.link, "_blank");
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <LoadingButton
                        loading={loading}
                        disabled={
                          tarea.estado &&
                          (tarea.estado === "TERMINADA" ||
                            tarea.estado === "FACTURADO" ||
                            tarea.estado === "PENDIENTE DE FACTURACION")
                        }
                        onClick={() => {
                          setElementoEliminar(value);
                          setMostrarAlertaEliminacion(true);
                          //removeFile(value.nombreArchivo, value.id);
                        }}
                      >
                        <DeleteIcon />
                      </LoadingButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </Card>
        {mostrarAlertaEliminacion && elementoEliminar && (
          <AlertaMensajeEliminacion
            mensaje="¿Seguro que deseas eliminar el archivo?"
            open={mostrarAlertaEliminacion}
            setOpen={setMostrarAlertaEliminacion}
            aceptar={() => {
              removeFile(elementoEliminar.nombreArchivo, elementoEliminar.id);
            }}
            mensajeBotonAceptar={"Eliminar"}
          />
        )}
      </RootStyle>
    </Container>
  );
}
