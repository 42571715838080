import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Typography, Grid, Button, Stack } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { Icon } from "@iconify/react";

import { API_SERVER, dbx } from "../utils/urlBack_End";
import FullWidthTabs from "../layouts/FullWidthTabs";
import DetallesTarea from "../components/tareas/DetallesTarea";
import Subtareas from "../components/tareas/Subtareas";
import EmpleadosAsociados from "../components/tareas/EmpleadosAsociados";
import ArchivosAdjuntos from "../components/tareas/ArchivosAdjuntos";
import { Alerta } from "../components/Alerta";
import FacturasTarea from "src/components/tareas/FacturasTarea";
import Comisiones from "src/components/tareas/Comisiones";
import arrowFill from "@iconify/icons-eva/arrow-back-fill";
import ClavesCliente from "src/components/tareas/ClavesCliente";
import { useSelector } from "react-redux";
import { tareasApi } from "src/services/tareasApi";
import { empleadosApi } from "src/services/empleadosApi";
import { tiposApi } from "src/services/tiposApi";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  position: "relative",
}));

// ----------------------------------------------------------------------

export default function EditarTarea(props) {
  const navigate = useNavigate();
  const { empleado: user } = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("success");
  const [message, setMessage] = useState("");

  const { data: monedas } = tiposApi.useGetIndicadoresQuery();

  const {
    data: tarea = null,
    isLoading: isLoadingTarea,
    isSuccess: isSuccessTarea,
  } = tareasApi.useGetTareaQuery(id);

  //const [actualizarEstadoTarea] = tareasApi.useUpdateCambiaEstadoMutation();

  const [addHistorialAcciones] = empleadosApi.useAddHistorialAccionesMutation();

  useEffect(() => {
    let urlActual = window.location.href;
    if (urlActual.includes("http://localhost:3000")) {
    } else {
      addHistorialAcciones({
        user: user.rut,
        body: {
          urlAccion: urlActual,
        },
      })
        .unwrap()
        .then(() => {})
        .catch(() => {});
    }
  }, [addHistorialAcciones, user.rut]);

  // Empleado
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [empleado, setEmpleado] = useState("");
  // Cliente
  const [nombreCliente, setNombreCliente] = useState("");
  // Tarea
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaTermino, setFechaTermino] = useState("");
  const [fechaLimite, setFechaLimite] = useState("");
  const [tipoTarea, setTipoTarea] = useState("");
  const [estadoTarea, setEstadoTarea] = useState("");
  const [tipoMonedaTarea, setTipoMonedaTarea] = useState("");
  const [valorTarea, setValorTarea] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [comentario, setComentario] = useState("");
  const [duracionTarea, setDuracionTarea] = useState("");
  const [tipoDuracionTarea, setTipoDuracionTarea] = useState("");
  const [refEmpleado, setRefEmpleado] = useState("");
  const [urlArchivo, setUrlArchivo] = useState("");

  const [tipoMonedasObtenidas, setTipoMonedasObtenidas] = useState(false);

  const ESTADOS_TAREA = [
    "PENDIENTE",
    "EN PROCESO",
    "OBSERVADA SII",
    "TERMINADA",
    "FACTURADO",
    "CANCELADO O RECHAZADO",
  ];
  const TIPOS_DURACION = ["HORAS", "MINUTOS", "DIAS"];

  // Sucursal
  const [empleados, setEmpleados] = useState([]);

  const fetchTask = useCallback(
    async function () {
      if (isSuccessTarea || tarea) {
        // if (tarea.estado === "PENDIENTE" && tarea.refEmpleado === user.rut) {
        //   actualizarEstadoTarea({
        //     id: id,
        //     body: {
        //       estado: "EN PROCESO",
        //       fechaTermino: "",
        //     },
        //   })
        //     .unwrap()
        //     .then(() => { });
        // }

        const promiseEmpleado = await axios.get(
          `${API_SERVER}/empleados/${tarea.refEmpleado}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        const promiseCliente = await axios.get(
          `${API_SERVER}/clientes/${tarea.refCliente}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        const promiseEmpleados = await axios.get(
          `${API_SERVER}/empleados/empleadoSucursal/${tarea.sucursal}/ALL`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        Promise.all([promiseEmpleado, promiseCliente, promiseEmpleados])
          .then((res) => {
            const empleadoAux = res[0].data[0];
            empleadoAux.nombre =
              empleadoAux.nombre + " " + empleadoAux.apellidos;
            const cliente = res[1].data;
            const empleadosAux = res[2].data.map((e) => ({
              nombre: e.nombre + " " + e.apellidos,
              refEmpleado: e.refEmpleado,
            }));

            setEmpleado(empleadoAux);
            setNombreEmpleado(empleadoAux.nombre);
            setNombreCliente(cliente.nombre);
            setEmpleados(empleadosAux);
            setFechaInicio(tarea.fechaInicio);
            setFechaTermino(tarea.fechaTermino);
            setFechaLimite(tarea.fechaLimite);
            setTipoTarea(tarea.tipoTarea);
            setEstadoTarea(tarea.estado);
            setTipoMonedaTarea(tarea.tipoMonedaTarea);
            setValorTarea(tarea.valorTarea);
            setVendedor(tarea.vendedor);
            setComentario(tarea.comentario);
            setDuracionTarea(tarea.duracionTarea);
            setTipoDuracionTarea(tarea.tipoDuracion);
            setSupervisor(tarea.supervisor);
            setRefEmpleado(tarea.refEmpleado);
            setUrlArchivo(tarea.urlArchivo);
          })
          .catch((ex) => {
            setError(
              "Ha ocurrido un error al conectarse con la base de datos."
            );
            setShowError(true);
          });
      }
    },
    [isSuccessTarea, tarea]
  );

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  useEffect(() => {
    dbx
      .filesListFolder({ path: `/Tareas/${id}` })
      .then(() => {})
      .catch(() => {
        dbx
          .filesCreateFolderV2({ path: `/Tareas/${id}` })
          .then((response) => {})
          .catch((error) => {});
      })
      .finally(() => {
        dbx
          .filesListFolder({ path: `/Tareas/${id}/SubTareas` })
          .then((respuesta) => {})
          .catch((e) => {
            dbx
              .filesCreateFolderV2({ path: `/Tareas/${id}/SubTareas` })
              .then((response) => {})
              .catch((error) => {});
          });
      });
  }, [id]);

  useEffect(() => {
    fetch("https://mindicador.cl/api")
      .then(function (response) {
        return response.json();
      })
      .then(function (dailyIndicators) {
        let dataArreglo = [
          Math.round(dailyIndicators.uf.valor),
          Math.round(dailyIndicators.utm.valor),
          Math.round(dailyIndicators.dolar.valor),
        ];
        setTipoMonedasObtenidas(dataArreglo);
      })
      .catch(function (error) {
        setError(
          "Ha ocurrido un error al solicitar los valores de las monedas de la base de datos."
        );
        setShowError(true);
      });
  }, []);

  const updateEmployee = (emp) => {
    setRefEmpleado(emp.refEmpleado);
    setNombreEmpleado(emp.nombre);
    setEmpleado(emp);
  };

  return (
    <>
      <Container>
        <RootStyle>
          <Grid container>
            <Grid item xs={2} md={2}>
              <Button
                variant="contained"
                color="info"
                startIcon={<Icon icon={arrowFill} />}
                onClick={() => {
                  navigate("/dashboard/tareas");
                }}
              >
                Volver
              </Button>
            </Grid>
            <Grid item xs={10} md={10}>
              <Stack>
                <Typography variant="h4" gutterBottom>
                  CLIENTE: {nombreCliente ? nombreCliente : ""}
                </Typography>
                <Typography variant="h5" gutterBottom color="primary">
                  TAREA: {tarea?.nombre}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          {!isLoadingTarea && (
            <Card style={{ padding: "1rem" }}>
              {tarea &&
              nombreEmpleado &&
              (tarea.tipoTarea === "ESPECIAL" ||
                tarea.tipoTarea === "NORMAL") ? (
                <FullWidthTabs
                  labels={[
                    "Detalles",
                    "Datos Cliente",
                    "Empleados Asociados",
                    "Subtareas",
                    "Archivos Adjuntos",
                    "Facturas",
                    "Comisiones",
                  ]}
                  components={[
                    <DetallesTarea
                      tarea={tarea}
                      ESTADOS_TAREA={ESTADOS_TAREA}
                      detallesTarea={{
                        nombreCliente,
                        nombreEmpleado,
                        fechaInicio,
                        fechaTermino,
                        fechaLimite,
                        tipoTarea,
                        estadoTarea,
                        setEstadoTarea,
                        tipoMonedaTarea,
                        valorTarea,
                        supervisor,
                        setSupervisor,
                        vendedor,
                        comentario,
                        setComentario,
                        duracionTarea,
                        setDuracionTarea,
                        tipoDuracionTarea,
                        setTipoDuracionTarea,
                        urlArchivo,
                        TIPOS_DURACION,
                        updateEmployee,
                        refEmpleado,
                        setRefEmpleado,
                        setFechaTermino,
                        setFechaLimite,
                        setNombreEmpleado,
                        setEmpleado,
                        empleado,
                      }}
                      empleados={empleados}
                    />,
                    <ClavesCliente
                      tarea={tarea}
                      setShowAlert={setShowAlert}
                      setColor={setColor}
                      setMessage={setMessage}
                    />,
                    <EmpleadosAsociados tarea={tarea} />,
                    <Subtareas tarea={tarea} />,
                    <ArchivosAdjuntos
                      tarea={tarea}
                      setShowError={setShowError}
                      setError={(msg) => {
                        setError(msg);
                        setShowError(true);
                      }}
                    />,
                    <FacturasTarea
                      tarea={tarea}
                      monedas={monedas}
                      tipoMonedasObtenidas={tipoMonedasObtenidas}
                    />,
                    <Comisiones
                      tarea={tarea}
                      setShowAlert={setShowAlert}
                      setColor={setColor}
                      setMessage={setMessage}
                    />,
                  ]}
                />
              ) : (
                <FullWidthTabs
                  labels={[
                    "Detalles",
                    "Datos Cliente",
                    "Empleados Asociados",
                    "Subtareas",
                    "Archivos Adjuntos",
                  ]}
                  components={[
                    <DetallesTarea
                      tarea={tarea}
                      ESTADOS_TAREA={ESTADOS_TAREA}
                      detallesTarea={{
                        nombreCliente,
                        nombreEmpleado,
                        fechaInicio,
                        fechaTermino,
                        fechaLimite,
                        tipoTarea,
                        estadoTarea,
                        setEstadoTarea,
                        tipoMonedaTarea,
                        valorTarea,
                        supervisor,
                        setSupervisor,
                        vendedor,
                        comentario,
                        setComentario,
                        duracionTarea,
                        setDuracionTarea,
                        tipoDuracionTarea,
                        setTipoDuracionTarea,
                        urlArchivo,
                        TIPOS_DURACION,
                        updateEmployee,
                        refEmpleado,
                        setRefEmpleado,
                        setFechaTermino,
                        setFechaLimite,
                        setNombreEmpleado,
                        setEmpleado,
                        empleado,
                      }}
                      empleados={empleados}
                    />,
                    <ClavesCliente tarea={tarea} />,
                    <EmpleadosAsociados tarea={tarea} />,
                    <Subtareas tarea={tarea} />,
                    <ArchivosAdjuntos
                      tarea={tarea}
                      setShowError={setShowError}
                      setError={(msg) => {
                        setError(msg);
                        setShowError(true);
                      }}
                    />,
                  ]}
                />
              )}
            </Card>
          )}

          <Alerta
            showAlert={showError}
            setShowAlert={setShowError}
            color="error"
            message={error}
          />
          {showAlert && (
            <Alerta
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              color={color}
              message={message}
            />
          )}
        </RootStyle>
      </Container>
    </>
  );
}
